.centova-streaming-player {
  text-align: center;
  display: flex;
  flex-direction: column; /* Stack the children vertically */
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  min-height: 3vh; /* Adjust the height to your preference */
  background-color: rgba(255, 255, 255, 0.2); /* Transparent white background */
  border-radius: 10px; /* Rounded corners */
  padding: 10px; /* Add some padding */
  width: 40%; /* Adjust the width to your preference */
  margin: 0 auto; /* Center horizontally */
}

.loading {
  margin: 20px;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 80%;
}


.metadata-container {
  display: flex; /* Changed to flex */
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  margin-right: 1em;
}

.metadata {
  font-size: 18px;
  color: #e9bd0ce3;
  margin-right: 10px;
  width: 100%;
  font-weight: bold;
}

.stream-details {
  font-size: 15px;
  color: #e9bd0ce3;
  font-size: 15px;
  color: #e9bd0ce3;
  margin: 10px auto;
}

.controls-container {
  display: flex; /* Changed to flex */
  flex-direction: row; /* Changed to row for side-by-side */
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 10px; /* Adjusted margin */
  margin-bottom: 20px;
  
}

.play-pause-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #0f9e48;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
  
}

.play-pause-button.playing {
  background-color: #0f9e48; /* Keep it green when playing */
}

.play-pause-button svg {
  width: 30px;
  height: 30px;
  fill: black; /* Set the icon color to black */
}

/* Add other styles as needed */


.volume-control {
  display: flex;
  
}
.volume-control input[type="range"]::before {
  background: linear-gradient(to right, #0f9e48 0%, #0f9e48 100%);
}


.volume-control .volume-slider {
  width: 80px;
  height: 4px;
  border-radius: 2px;
  background: linear-gradient(to right, #0f9e48 0%, #fcdf01 13%, #ff0000 var(--slider-position, 100%), #0f9e48 var(--slider-position, 0%), #ffffff 100%);
  -webkit-appearance: none; /* Remove default styles (Webkit/Blink) */
  appearance: none;
  transition: background-color 0.2s ease; /* Add smooth transition */
}
/* Style the volume slider thumb */
.volume-control .volume-slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Remove default styles (Webkit/Blink) */
  appearance: none;
  width: 12px;
  height: 12px;
  background-color: #ffffff; /* White color for thumb */
  border: 2px solid #fff; /* Green color border */
  border-radius: 50%;
  cursor: pointer;
}

.volume-control .volume-slider::-moz-range-thumb {
  width: 12px;
  height: 12px;
  background-color: #0f9e48; /* White color for thumb */
  border: 2px solid #fff; /* Green color border */
  border-radius: 50%;
  cursor: pointer;
}

.volume-control .volume {
  font-size: 14px;
  color: #e9bd0ce3;
  margin-right: 15px;
  margin-top: -9px;
  
}

.volume-control {
  display: none;
  
}

@media only screen and (max-width: 768px) {
  .container {
    width: 90% !important;
  }
}

@media (min-width: 768px) {
  .controls-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .controls {
    display: flex;
    flex-direction: row; /* Align volume slider and volume icon horizontally */
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    width: 100%; /* Make the controls container take the full width */
  }

  .controls button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #fcdf01;
    color: #000;
    font-size: 16px;
    cursor: pointer;
    border: none;
    outline: none;
    padding: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease;
    margin-bottom: 10px;
  }

  .controls button.playing {
    background-color: #0f9e48;
  }

  .controls button.paused {
    background-color: #0f9e48;
  }

  .volume-control {
    display: flex;
    align-items: center; /* Center items horizontally */
    margin-right: -50px;
  }

  .volume-control input[type="range"] {
    margin-right: 10px; /* Add some right margin to separate the slider and volume icon */
  }

  .volume-control .volume {
    font-size: 14px;
    color: #e9bd0ce3;
    margin-top: 3px;
  }
}



.miniplayer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}

.miniplayer-controls button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  background-color: #0a8e03;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
}

.miniplayer-metadata {
  font-size: 14px;
  color: #e9bd0ce3;
}

.image-placeholder {
  display: flex;
  flex-direction: column;
  margin-right: 1em;
}

.image-placeholder img{
  width: 90px;
  height: 90px;
  margin-bottom: 10px;
  margin-right: 14px;
  font-size: 14px;
  color: #e9bd0ce3;
  border-radius: 84px;
  box-shadow: 0px 0px 0px 7px #0f9e48;
}

.show-title {
  width: 100%;
  background: #fb4c4b;
  padding: 0.6em 0.6em;
  margin: 0 !important;
  color: #fff;
}