  .slider {
    position: relative;
    width: 100%;
    height: 700px;
    overflow: hidden;
    margin-top: 15px;
  }
  
  .slider::before {
    content: '';
    background-image: url('../../assets/images/slider-mask.png');
    background-repeat: repeat;
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  /* Rest of the CSS code */
  .swiper-slide img {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
  
  
  .slider-wrapper {
    display: flex;
    transition: transform 0.3s ease-in-out;
  }
  
  .slide {
    flex: 0 0 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .slide-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; /* Center the image based on the height */
  }
  
  .prev-button,
  .next-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
  }
  
  .prev-button {
    left: 10px;
  }
  
  .next-button {
    right: 10px;
  }
  